import { instance as axios } from "../index";

/**
 * 登录
 * @param {*} data
 * @returns
 */
export const loginPost = (data) =>
  axios.post("/platform/login/customer", data).then((res) => res);

/**
 * 专家登录
 * @param {*} data
 * @returns
 */
export const expertLoginPost = (data) =>
  axios.post("/system/web/expert/doLogin", data).then((res) => res);

export const getInfo = (data) =>
  axios.get("/system/web/user/info", data).then((res) => res);

/**
 * 忘记密码
 * @param {*} data
 * @returns
 */
export const findPwdPost = (data) =>
  axios.post("/system/web/user/forgetPwd", data).then((res) => res);

/**
 * 发送验证码
 * @param {*} data
 * @returns
 */
export const sendSmsCodeGet = (data) =>
  axios.get("/system/web/sendSmsCode", { params: data }).then((res) => res);

/**
 * 个人中心首页
 * @param {*} data
 * @returns
 */
export const getUserHome = (data) =>
  axios.get("/system/web/user/home", { params: data }).then((res) => res);

/**
 * 退出登录
 * @param {*} data
 * @returns
 */
export const logoutPost = (data) =>
  axios.post("/system/web/logout", data).then((res) => res);

export const logoutPost2 = (data) =>
  axios.post("/system/web/expert/logout", data).then((res) => res);

/**
 * 根据id获取信息
 * @param {*} data
 * @returns
 */
export const getDoctorByUserId = (data) =>
  axios
    .get("/base-data/doctor/getByUserId", { params: data })
    .then((res) => res);

/**
 * 更新用户信息
 * @param {*} data
 * @returns
 */
export const updateDoctorPost = (data) =>
  axios.post("/base-data/doctor/update", data).then((res) => res);

/**
 * 修改密码
 * @param {*} data
 * @returns
 */
export const modifyPwdPost = (data) =>
  axios.post("/system/web/user/modifyPwd", data).then((res) => res);

/**
 * 新版修改密码
 * @param {*} data 
 * @returns 
 */
export const updatePwd = (data) => {
  return axios.post("/platform/userCustomer/updatePwd", data).then((res) => res);
}

/**
 * 新版忘记密码
 * @param {*} data 
 * @returns 
 */
export const forgotPwd = (data) => {
  return axios.post("/platform/userCustomer/forgotPwd", data).then((res) => res);
}

/**
 * 诊所入住申请
 * @param {*} data
 * @returns
 */
export const customerApplyPost = (data) =>
  axios.post("/base-data/customerManage/web/apply", data).then((res) => res);

/**
 * 获取OSS sts
 * @param {*} data
 * @returns
 */
export const getOssSts = (data) =>
  axios.get("/file-server/oss/acquireSts", { params: data }).then((res) => res);

export const baseInfo = (data) =>
  axios.get("/system/baseInfo", { params: data }).then((res) => res);


export const getBluetoothMac = (data) => {
  return axios.post("/platform/app/getBluetoothMac", data);
}

export const delBluetoothMac = (data) => {
  return axios.post("/platform/app/delBluetoothMac", data);
}

export const androidAutoLogin = (data) => {
  return axios.post("/openapi-center/open/login/appLogin", data);
}
/** 外部自动登录 */
export const outsideLogin = (data) => {
  return axios.post("/openapi-center/open/login/outsideLogin", data).then((res) => res);
}
